import { useContext } from 'react';
import { CustomiseVoucherContext } from '../components/Context/Context';
import useAuthenticationState from './useAuthenticationState';
import { useAppContext } from '../lib/contextProviders/contexts/appContext';
import { B2B } from '../constants';

const useBusinessEntityData = () => {
  const { entityData } = useContext(CustomiseVoucherContext);
  const {
    appState: { siteType },
  } = useAppContext();
  const { isConfirmedNotLoggedIn } = useAuthenticationState();
  const isB2BSite = siteType === B2B;
  const isEntityDataReady = isConfirmedNotLoggedIn || entityData !== null;
  const isUserAccHasBusinessEntity = isEntityDataReady && !!entityData?.id;

  return {
    isB2BSite,
    isEntityDataReady,
    isUserAccHasBusinessEntity,
    entityData,
  };
};

export default useBusinessEntityData;

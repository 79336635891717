import { createContext, useContext } from 'react';
import { appInitialState } from '../reducers/appReducer';

const AppContext = createContext({
  appState: appInitialState,
});

export const useAppContext = () => useContext(AppContext);

export default AppContext;

import { B2C, SITE_TYPE } from '../../../constants';
import { getCookie } from '../../utils';

export const appInitialState = {
  isMobile: true,
  siteType: getCookie(SITE_TYPE) || B2C,
};

export default function appReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case 'SET_IS_MOBILE':
      return { ...state, isMobile: payload };
    case 'SET_SITE_TYPE':
      return { ...state, siteType: payload };
    default:
      return state;
  }
}

import nookies from 'nookies';
import { useAuth0 } from '@auth0/auth0-react';
import { AUTH_TOKEN_KEY } from '../constants/index';

const useAuthenticationState = () => {
  const { [AUTH_TOKEN_KEY]: authTokenFromCookie } = nookies.get();
  const { isAuthenticated, isLoading } = useAuth0();

  return {
    // in most occasions, we should use the loggedIn flags to determine user's login state, this detects user's login state in
    // both browser and web view correctly (either authenticated locally or via injected authToken cookie)
    isConfirmedLoggedIn: !!authTokenFromCookie || (!isLoading && isAuthenticated),
    isConfirmedNotLoggedIn: !authTokenFromCookie && !isLoading && !isAuthenticated,
    isLoggingIn: !authTokenFromCookie && isLoading,
    // auth0Authenticated flags only provides the login state from auth0 (authenticated locally)
    isConfirmedAuth0Authenticated: !isLoading && isAuthenticated,
    isConfirmedAuth0NotAuthenticated: !isLoading && !isAuthenticated,
    isAuth0Authenticating: isLoading,
  };
};

export default useAuthenticationState;

const GTM_ENV_VAR = {
  LOGIN_STATUS: 'loggedin_status',
  PAGE_TYPE: 'page_type',
  SCREEN_NAME: 'screen_name',
  EVENT_CAT: 'event_category',
  EVENT_ACTION: 'event_action',
  EVENT_LABEL: 'event_label',
  EVENT_NAME: 'event',
  ASSET_TYPE: 'asset_type',
  USER_ID: 'user_id',
  DEVICE_ID: 'device_id',
  ENVIRONMENT: 'environment',
  AMPLITUDE_EVENT_NAME: 'amplitude_event_name',
  AMPLITUDE_PROPERTY_TYPE: 'amplitude_property_type',
  AMPLITUDE_PROPERTY_NAME: 'amplitude_property_name',
  AMPLITUDE_CTA_LOCATION: 'call to action location',
  AMPLITUDE_CTA_SUB_LOCATION: 'call to action sublocation',
  AMPLITUDE_GIFTING_SEND_VIA: 'send via',
  AMPLITUDE_GIFTING_RECIPIENT: 'recipient',
  AMPLITUDE_GIFTING_MESSAGE: 'message',
  AMPLITUDE_GIFTING_DATE_OF_DELIVERY: 'date of delivery',
  AMPLITUDE_GIFTING_CATEGORY: 'category',
  AMPLITUDE_GIFTING_CARD_DESIGN: 'card design',
  AMPLITUDE_GIFTING_MULTIPLE_RECIPIENTS: 'multiple recipients',
  AMPLITUDE_GIFTING_FUTURE_DATE: 'future date',
  AMPLITUDE_VERTICAL: 'vertical',
  AMPLITUDE_GIFTING_GIFT_ID: 'gift id',
  AMPLITUDE_DENOMINATION: 'denomination',
  AMPLITUDE_GIFTING_ORDER_NUMBER: 'order number',
  AMPLITUDE_GIFTING_CUSTOMER_LOGIN_STATUS: 'customer login status',
  AMPLITUDE_GIFTING_MESSAGE_CATEGORY: 'message category',
  MEMBER_TYPE: 'member type',
  MESSAGE: 'message',
  GIFTING_ORDER_STATUS: 'gifting order status',
  GIFTING_ORDER_ID: 'gifting order id',
};

const GIFTING_AMP_NAME = {
  EGIFTING_CUSTOMIZE_SELECTION: 'egifting | customize selection',
  GIFTING: 'egifting',
  EVENT_GIFTING_ORDER_DETAILS: 'egifting | order details',
  EVENT_GIFTING_ORDER_SUCCESS: 'egifting | order success',
};

const PAGE_TYPES = {
  VOUCHER: 'e-gifting voucher',
  PAYMENT_CHECKOUT: 'checkout',
  CART: 'cart',
  CUSTOMISE: 'customize selection',
  HOME: 'home',
};

const SCREEN_NAME = {
  CHECKOUT: 'payment-egifting | checkout',
  CART: 'payment-egifting | cart',
  CUSTOMISE: 'payment-egifting | customize selection',
  HOME: 'payment-egifting | home',
};

const CATEGORY = {
  VOUCHER: 'Egifting',
};

module.exports = {
  GIFTING_AMP_NAME,
  GTM_ENV_VAR,
  SCREEN_NAME,
  PAGE_TYPES,
  CATEGORY,
};

import { GTM_ENV_VAR as GEV } from './eventTrackerFunction';

const AMP_EVENT_NAME = {
  PAGE_TILE: 'customize voucher page viewed',
  ADD_TO_CART: 'voucher added to cart',
  REVIEW_AND_CONFIRM: 'review and confirm clicked',
  CHECKOUT_COMPLETED: 'checkout place order clicked',
  ORDER_COMPLETED: 'order completed',
  VOUCHER_EDIT: 'voucher edit clicked',
  LOGGED_IN: 'logged in',
  EDIT_SAVED: 'edit voucher saved clicked',
  PREVIEW: 'voucher preview clicked',
  EDIT_CANCEL: 'edit voucher cancel clicked',
  REMOVE_CLICK: 'voucher removed from cart',
  COPY_CLICK: 'copy clicked',
  SUBMIT_ENQUIRY_CLICK: 'Submit enquiry clicked',
  UPDATE_ERROR_VIEWED: 'Upload error viewed',
  DOWNLOAD_INVOICE_CLICKED: 'download invoice clicked',
  EMPTY_CART: 'empty cart clicked',
  CATEGORY_CHIP_CLICK: 'category chip clicked',
  VERTICAL: 'egifting',
  SEND_NOW_CLICK: 'send now clicked',
  CARD_DESIGN_TILE_CLICK: 'card design tile clicked',
  CUSTOMISATION_CANCELLED: 'customisation cancelled',
  BUSINESS_ACCOUNT_POPUP: 'business account application popup clicked',
  TOGGLE_POPUP_VIEWED: 'toggle popup viewed',
  TOGGLE_POPUP_CLICK: 'toggle popup clicked',
  BREADCRUMB_CLICK: 'breadcrumb clicked',
  DESIGN_DROPDOWN_CLICK: 'design dropdown clicked',
  DESIGN_SCROLL_CLICK: 'design scroll clicked',
  QUANTITY_ICON_CLICK: 'quantity icon clicked',
  EDIT_QUANTITY_VALUE: 'edit quantity value',
  EMPTY_CART_STATE_BUTTON_CLICK: 'empty cart state button clicked',
  CHANGE_CARD_BUTTON_CLICK: 'change card button clicked',
  ORDER_REMOVED_FROM_CART: 'order removed from cart',
  APPLY_NOW_CLICKED: 'apply now clicked',
};

// To be add.
// const amplitudeEventNames = Object.values(AMP_EVENT_NAME);

const sendGaEventToAmplitude = (event, amplitudeClient) => {
  if (!amplitudeClient) {
    return;
  }

  const ampEventName = event[GEV.AMPLITUDE_EVENT_NAME];

  amplitudeClient.getInstance().logEventWithTimestamp(ampEventName, event, Date.now());
};

const pushDataLayerToAmplitude = (amplitudeClient) => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer.forEach((gaEvent) => {
    sendGaEventToAmplitude(gaEvent, amplitudeClient);
  });
};

export { AMP_EVENT_NAME, sendGaEventToAmplitude, pushDataLayerToAmplitude };

import { parseISO, format } from 'date-fns';

const _get = require('lodash/get');

// getting email value from user data
// old flow: accountData.defaultEmail.email
// new flow: accountData.email
export const getEmail = (accountData) => {
  return _get(accountData, 'email');
};

export const isPastDate = (expiresAt, isListing = false) => {
  const formattedDate = isListing ? expiresAt : format(parseISO(expiresAt), 'YYY-MM-dd');
  const todaysDate = new Date();
  todaysDate.setHours(0, 0, 0, 0);
  return new Date(todaysDate) > new Date(formattedDate);
};
